.home-page-section2-wrapper {
  color: $blue;
  min-height: 80vh;
  overflow: hidden;
  .home-page-section2 {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px;
    > .title-wrapper {
      margin: 0 auto 48px;
      ul {
        text-align: justify;
        font-size: 28px;

        @media #{$phone} {
          font-size: 18px;
        }
      }
    }
    .block-wrapper {
      position: relative;
      overflow: hidden;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .block {
        padding: 0 20px;
        display: inline-block;
        text-align: center;
        height: 200px;
        margin-bottom: 48px;
        flex: 1 1 300px;

        &.queue-anim-leaving {
          position: relative !important;
        }
        .icon {
          text-align: center;
          border-bottom: 2px solid $primary-color;
          padding-bottom: 20px;
        }
        .content0-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 32px;
          margin: 10px auto;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    .btn-wrapper {
      text-align: center;
    }
  }
}
.test-list-title {
  background: $blue;
  color: white;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.test-wrapper {
  text-align: center;
  font-size: 20px;

  .header {
    h3 {
      color: $blue;
      margin-bottom: 5px;
      font-weight: bold;
    }

    span {
      border-bottom: 2px solid $primary-color;
      display: inline-block;
      padding: 0 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  p {
    margin: 5px 0;
  }
}

@media screen and (max-width: 767px) {
  // .home-page-section2-wrapper {
  //   height: 880px;
  // }
}
