//colors:
$background: #fff;
$primary-color: #19b99f;
$highlight-color: #297db4;
$line-color: #e9e9e9;
$selection-color: #cae6f3;
$darkGreen: #268374;
$turquoise: #19a0b9;
$red: #ff0000;
$blue: #0d3461;
$gray: #95989a;
$lightGray: #eeeeee;
$darkGray: #727272;

$shadow-color: rgba(0, 0, 0, 0.15);

$bottom-bar-bg-color: #262626;
$bottom-bar-line-color: #000;

$template-bg-color: #e4e4e4;
$template-bg-color-light: #ececec;
$template-nav-bg-color: fade($template-bg-color, 95%);
$template-text-color: #ccc;
$template-text-title-color: #bcbcbc;
$template-text-color-light: $primary-color;
$template-footer-text-color: #999;
//media queries:
// $tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
$phone: "only screen and (max-width: 767px)";
$mobile: "only screen and (max-width: 992px)";
$desktop: "only screen and (min-width: 993px)";
//
// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
