.table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: $primary-color;
    cursor: pointer;
    font-size: 30px;
  }
}
.table-footer {
  margin-top: 20px;
}

.ant-collapse {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  border: none !important;

  > .ant-collapse-item {
    border-bottom: 1px solid white !important;
    &:last-child {
      border-bottom: none !important;
    }
    > .ant-collapse-header {
      color: white !important;
    }
  }
}

.faq {
  h1 {
    text-transform: uppercase;
    color: $primary-color;
  }
  .ant-collapse {
    background-color: $primary-color;
  }

  &.glucosio {
    h1 {
      color: $blue;
    }
    .ant-collapse {
      background-color: $blue;
    }
  }

  &.colesterolo {
    h1 {
      color: $primary-color;
    }
    .ant-collapse {
      background-color: $primary-color;
    }
  }

  &.emoglobina {
    h1 {
      color: $gray;
    }
    .ant-collapse {
      background-color: $gray;
    }
  }

  &.trigliceridi {
    h1 {
      color: $turquoise;
    }
    .ant-collapse {
      background-color: $turquoise;
    }
  }

  &.profiloLipidico {
    h1 {
      color: $darkGreen;
    }
    .ant-collapse {
      background-color: $darkGreen;
    }
  }
}
