.banner {
  width: calc(100% + 100px);
  margin: 0 -50px;
  height: calc(100vh - 64px);
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("../assets/screen.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$phone} {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }

  & .banner-content-wrapper {
    display: inline-block;
    color: $template-text-color-light;
    text-align: center;
    width: 550px;

    > .queue-anim-leaving {
      position: relative !important;
    }
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.534);
  }
  & .banner-logo {
    display: inline-block;
    width: 350px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  & .banner-title {
    color: $blue;
    margin: 0;
    word-wrap: break-word;
    min-height: 24px;
  }
  & .banner-button {
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    line-height: 40px;
    font-size: 16px;
    height: 40px;
    transition: background 0.45s $ease-out, box-shadow 0.45s $ease-out;
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 0 10px $blue;
    }
    &:focus {
      color: $primary-color;
      border-color: $primary-color;
    }
    &.queue-anim-leaving {
      width: auto;
    }
  }
  & .banner-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    color: $template-text-color-light;
  }
}

@media #{$phone} {
  .banner {
    background-attachment: inherit;
    & .banner-content-wrapper {
      width: 90%;
    }
    & .banner-logo {
      width: 90%;
      left: 0;
    }
  }
}
