header {
  background: $lightGray;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px fade(#000, 15);
  position: fixed;
  top: 0;
  .home-page {
    padding: 0 24px;
  }
  .logo {
    display: inline-block;
    position: relative;
    width: 150px;
    line-height: 64px;
    & img {
      height: 30px;
      vertical-align: middle;
      display: inline-block;
    }
    & a {
      display: block;
    }
  }
  .menu {
    float: right;
    > .ant-menu {
      line-height: 62px;
      background: transparent;
      color: $template-text-color-light;
      height: 64px;
      border-bottom-color: transparent;
      position: relative;
      a,
      .ant-menu-submenu .ant-menu-submenu-title {
        color: $template-text-color-light;
        &:hover {
          color: $highlight-color;
        }
      }
    }
  }
  .ant-menu-item-selected a {
    color: $primary-color;
  }
}

@media #{$phone} {
  header {
    .logo {
      z-index: 101;
    }
    &.home-page-wrapper .home-page {
      padding: 0 24px;
    }
    & .menu {
      background: $template-bg-color;
      height: auto;
      float: inherit;
      position: relative;
      left: -24px;
      width: calc(100% + 48px);
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.3s $ease-in-out, height 0.3s $ease-in-out;

      .ant-menu {
        height: auto;
        overflow: hidden;

        background: $template-bg-color;
        .ant-menu-item-selected {
          border: none;
        }
        a,
        .ant-menu-submenu .ant-menu-submenu-title {
          color: $template-text-color-light;
          &:hover {
            color: $template-text-color-light;
          }
        }
        .ant-menu-item-selected a {
          color: $template-text-color-light;
        }
      }

      & li {
        padding: 0 24px;
        &.ant-menu-submenu {
          padding: 0;
        }
      }
      & .ant-menu-submenu .ant-menu-sub {
        padding: 0 24px;
      }
    }
    .mobile-menu {
      width: 16px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 100;
      em {
        display: block;
        width: 100%;
        height: 2px;
        background: $primary-color;
        margin-top: 4px;
        transition: transform 0.3s $ease-in-out, opacity 0.3s $ease-in-out;
      }
      :first-child {
        margin-top: 0;
      }
    }

    & .open {
      height: auto;
      .mobile-menu {
        em {
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }
      > .menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
