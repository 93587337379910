@import "variables";
@import "nav";
@import "banner";
@import "measure-types";
@import "results";

.layout.ant-layout {
  background-color: $background;

  .main-content {
    min-height: calc(100vh - 132px);
    padding: 0 50px;
    margin-top: 64px;

    @media #{$phone} {
      padding: 0 15px;
    }
  }
}
.page-title-wrapper {
  padding-top: 20px;
}
.add-patient-instrucions {
  margin-bottom: 15px;
}
.add-patient-success {
  margin-top: 15px;
}
.patients {
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: $selection-color;
  }
}
.form-wrapper {
  min-height: 50vh;
  .form {
    background-color: #fff;
    border-radius: 7px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;

    .form-forgot {
      // float: right;
    }
    .form-button {
      width: 100%;
    }
  }
}

.ant-tabs-nav-scroll {
  text-align: center;
}

.measure-type-tabs {
  @media only screen and (min-width: 768px) {
    .ant-tabs-nav {
      width: 100%;
      .ant-tabs-tab {
        width: 33%;
      }
    }
  }

  .measure-type {
    display: flex;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
}
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 132px);
}
